export function parseJSON(JSONString, defaultObject) {
  try {
    return JSONString ? JSON.parse(JSONString) : defaultObject
  } catch (err) {
    return defaultObject
  }
}

export const API_HOST = process.env.API_HOST || 'http://localhost:3000'
export const TEST_CARD_MESSAGE_ENABLED =
  process.env.TEST_CARD_MESSAGE_ENABLED === 'true'
export const CHECKOUT_BRANDS = parseJSON(process.env.CHECKOUT_BRANDS, [
  'visa',
  'mc',
  'cartebancaire',
  'amex',
])
