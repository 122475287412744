import querystring from 'querystring'

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import AdyenCheckout from '../../node_modules/@adyen/adyen-web/dist/es.modern'
import '@adyen/adyen-web/dist/adyen.css'

import { CHECKOUT_BRANDS, TEST_CARD_MESSAGE_ENABLED } from '../config'

import useApi from '../hooks/useApi'

import Button from '../components/Button'
import LoadingMessage from '../components/LoadingMessage'
import LoadingTitle from '../components/LoadingTitle'
import LoadingSubtitle from '../components/LoadingSubtitle'

const cardConfig = {
  hasHolderName: true,
  holderNameRequired: true,
  brands: CHECKOUT_BRANDS,
}

const Checkout = () => {
  const cardContainerRef = useRef()
  const checkoutRef = useRef()
  const sessionRef = useRef()
  const { t } = useTranslation()

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const paymentMethodIdRef = useRef(searchParams.get('payment_method_id'))

  const { action, token, promoCode, bulkPaymentId, brand } = useMemo(
    () => ({
      action: searchParams.get('action') || 'card_enrollment',
      token: searchParams.get('token'),
      promoCode: searchParams.get('promo_code'),
      bulkPaymentId: searchParams.get('bulk_payment_id'),
      brand: searchParams.get('brand') || 'boxy',
    }),
    [searchParams],
  )

  const {
    createAdyenSession,
    authorizeAdyenSession,
    authenticateAdyenSession,
    getAdyenSession,
  } = useApi({ token })

  useEffect(() => {
    try {
      sessionStorage.setItem('token', token)
    } catch (err) {
      console.error(err)
    }
  }, [token])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [hasPaymentData, setHasPaymentData] = useState(false)
  const [addPaymentButtonVisible, setAddPaymentButtonVisible] = useState(false)
  const [isCallingBank, setIsCallingBank] = useState(false)
  const [shouldStorePaymentMethod, setShouldStorePaymentMethod] =
    useState(false)

  const sendFeedbackToApp = useCallback(
    (data) => {
      if (
        window.ReactNativeWebView != null &&
        window.ReactNativeWebView.postMessage
      ) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            ...data,
          }),
        )
      }
    },
    [window.ReactNativeWebView],
  )

  const completeAuthentication = useCallback(
    async (paymentState) => {
      setIsCallingBank(true)

      const adyenSession = {
        _id: sessionRef.current._id,
        state: 'pending_authentication',
      }

      try {
        await authenticateAdyenSession(
          sessionRef.current._id,
          paymentState.data,
        )
      } catch (err) {
        console.error(err)

        adyenSession.state = 'client_error'
      }

      await handleSessionState(adyenSession)
    },
    [authenticateAdyenSession, getAdyenSession],
  )

  useEffect(() => {
    const _initCard = async () => {
      const sessionPayload = {
        action,
      }

      if (bulkPaymentId) {
        sessionPayload.bulk_payment_id = bulkPaymentId
      }

      try {
        const { body: sessionBody } = await createAdyenSession(sessionPayload)
        sessionRef.current = sessionBody
      } catch (err) {
        console.error(err)

        sendFeedbackToApp({
          error: true,
          message: err.message,
        })

        return
      }

      try {
        const checkout = await AdyenCheckout({
          // This sets environment, clientKey, and session
          ...sessionRef.current.checkout_params,

          // This sends the 3DS result to our API
          onAdditionalDetails: completeAuthentication,
        })

        checkoutRef.current = checkout
          .create('card', {
            ...cardConfig,
            onChange: ({ isValid }) => {
              setHasPaymentData(isValid)
            },
            onConfigSuccess: () => {
              if (!paymentMethodIdRef.current) {
                setAddPaymentButtonVisible(true)

                sendFeedbackToApp({
                  finishLoading: true,
                })
              } else {
                authorizePaymentMethod()
              }
            },
          })
          .mount(cardContainerRef.current)
      } catch (err) {
        console.error(err)

        sendFeedbackToApp({
          error: true,
          message: err.message,
        })
      }
    }

    _initCard()
  }, [createAdyenSession, completeAuthentication, bulkPaymentId])

  const authorizePaymentMethod = useCallback(async () => {
    setIsSubmitting(true)

    const authorizeBody = {
      client_data: checkoutRef?.current?.data,
      action,
    }

    if (promoCode) {
      authorizeBody.promo_code = promoCode
    }

    if (bulkPaymentId) {
      authorizeBody.bulk_payment_id = bulkPaymentId
    }

    if (shouldStorePaymentMethod) {
      authorizeBody.store_payment_method = true
    }

    if (paymentMethodIdRef.current) {
      authorizeBody.payment_method_id = paymentMethodIdRef.current
    }

    const adyenSession = {
      _id: sessionRef.current._id,
      state: 'pending_authentication',
    }

    let paymentAction

    try {
      const { body } = await authorizeAdyenSession(
        sessionRef.current._id,
        authorizeBody,
      )

      paymentAction = body?.action
    } catch (err) {
      adyenSession.state = 'error'

      if (err.response?.body?.error?.code === 'unsupported_card') {
        adyenSession.error_message = err.response.body.help
      }

      console.error(err)
    } finally {
      setIsSubmitting(false)
    }

    if (adyenSession.state !== 'pending_authentication') {
      setIsCallingBank(true)

      await handleSessionState(adyenSession)

      return
    }

    if (paymentAction) {
      // This mounts the 3DS in the Adyen view
      checkoutRef.current.handleAction(paymentAction)

      // This is for BulkPayments with an existing card. In this case,
      // we need to mount the card fields, but cannot show them to the
      // customer.
      if (paymentMethodIdRef.current) {
        sendFeedbackToApp({
          finishLoading: true,
        })
      }
    } else {
      setIsCallingBank(true)

      await handleSessionState(adyenSession)
    }

    setAddPaymentButtonVisible(false)
  }, [
    authorizeAdyenSession,
    completeAuthentication,
    shouldStorePaymentMethod,
    bulkPaymentId,
  ])

  const handleSessionState = useCallback(
    async (adyenSession) => {
      let start = Date.now()

      while (adyenSession.state === 'pending_authentication') {
        if (Date.now() - start > 60000) {
          adyenSession = {
            _id: sessionRef.current._id,
            state: 'timeout',
          }
          break
        }

        try {
          const { body } = await getAdyenSession(sessionRef.current._id)

          adyenSession = body
        } catch (err) {
          console.error(err)
        }

        if (adyenSession.state === 'pending_authentication') {
          await new Promise((resolve) => setTimeout(resolve, 500))
        }
      }

      if (adyenSession.state === 'succeeded') {
        setIsCallingBank(false)

        navigate(
          '/success?' + querystring.stringify({ action: adyenSession.action }),
          { replace: true },
        )
      } else {
        paymentMethodIdRef.current = null
        searchParams.delete('payment_method_id')

        sendFeedbackToApp({
          error: true,
          message: adyenSession?.error_message ?? t('default_error_message'),
        })

        setIsCallingBank(false)

        while (cardContainerRef.current == null) {
          await new Promise((resolve) => setTimeout(resolve, 500))
        }

        const checkout = await AdyenCheckout({
          ...sessionRef.current.checkout_params,
          // This sends the 3DS result to our API
          onAdditionalDetails: completeAuthentication,
        })

        checkoutRef.current = checkout
          .create('card', {
            ...cardConfig,
            onChange: ({ isValid }) => {
              setHasPaymentData(isValid)
            },
            onConfigSuccess: () => {
              setAddPaymentButtonVisible(true)
            },
          })
          .mount(cardContainerRef.current)
      }
    },
    [getAdyenSession, completeAuthentication, searchParams],
  )

  if (isCallingBank) {
    return (
      <LoadingMessage>
        <div>
          <LoadingTitle>{t('3ds_loading_title')}</LoadingTitle>
          <LoadingSubtitle>{t('3ds_loading_subtitle')}</LoadingSubtitle>
        </div>
      </LoadingMessage>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 20px)',
        padding: '0px 20px 20px 20px',
      }}
    >
      {addPaymentButtonVisible ? (
        <>
          {action === 'card_enrollment' ? (
            <div style={{ marginBottom: 24 }}>{t('bank_header')}</div>
          ) : null}

          {TEST_CARD_MESSAGE_ENABLED ? (
            <div style={{ marginBottom: 24 }}>{t('test_card_message')}</div>
          ) : null}
        </>
      ) : null}

      <div ref={cardContainerRef}></div>

      {addPaymentButtonVisible ? (
        <>
          {action !== 'card_enrollment' ? (
            <div style={{ marginTop: 24 }}>
              <input
                id="store_payment_method_checkbox"
                type="checkbox"
                name="store_payment_method"
                checked={shouldStorePaymentMethod}
                onChange={(event) =>
                  setShouldStorePaymentMethod(event.target.checked)
                }
              />
              <label htmlFor="store_payment_method_checkbox">
                {t('store_payment_method_label')}
              </label>
            </div>
          ) : null}

          {action === 'card_enrollment' || shouldStorePaymentMethod ? (
            <div style={{ marginTop: 24 }}>{t('bank_footer')}</div>
          ) : null}

          <div style={{ flex: 1 }}></div>

          <div>
            <Button
              onClick={authorizePaymentMethod}
              disabled={isSubmitting || !hasPaymentData}
              isLoading={isSubmitting}
              data-testid="add-payment-method-button"
              brand={brand}
            >
              &nbsp;{' '}
              {action === 'card_enrollment'
                ? t('add_payment_method')
                : t('use_payment_method')}
            </Button>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default Checkout
