import querystring from 'querystring'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import useApi from '../hooks/useApi'

import LoadingMessage from '../components/LoadingMessage'
import LoadingTitle from '../components/LoadingTitle'
import LoadingSubtitle from '../components/LoadingSubtitle'

const Callback = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()

  const [isCallingBank, setIsCallingBank] = useState(true)

  const token = useMemo(() => sessionStorage.getItem('token'), [])

  const { authenticateAdyenSession, getAdyenSession } = useApi({ token })

  const { action, redirectResult, adyenSessionId, bulkPaymentId } = useMemo(
    () => ({
      action: searchParams.get('action') || 'card_enrollment',
      redirectResult: searchParams.get('redirectResult'),
      adyenSessionId: searchParams.get('adyen_session_id'),
      bulkPaymentId: searchParams.get('bulk_payment_id'),
      brand: searchParams.get('brand') || 'boxy',
    }),
    [searchParams],
  )

  useEffect(() => {
    if (!redirectResult || !adyenSessionId) {
      sendFeedbackToApp({
        error: true,
        message: t('default_error_message'),
      })

      return
    }

    completeAuthentication({ data: { details: { redirectResult } } })
  }, [redirectResult, adyenSessionId])

  const sendFeedbackToApp = useCallback(
    (data) => {
      if (
        window.ReactNativeWebView != null &&
        window.ReactNativeWebView.postMessage
      ) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            ...data,
          }),
        )
      }
    },
    [window.ReactNativeWebView],
  )

  const completeAuthentication = useCallback(
    async (paymentState) => {
      setIsCallingBank(true)

      let adyenSession = {
        _id: adyenSessionId,
        state: 'pending_authentication',
      }

      try {
        await authenticateAdyenSession(adyenSessionId, paymentState.data)
      } catch (err) {
        console.error(err)

        adyenSession = {
          _id: adyenSessionId,
          state: 'client_error',
        }
      }

      let start = Date.now()

      while (adyenSession.state === 'pending_authentication') {
        const now = Date.now()
        if (now - start > 60000) {
          adyenSession = {
            _id: adyenSessionId,
            state: 'timeout',
          }
          break
        }

        try {
          const { body } = await getAdyenSession(adyenSessionId)

          adyenSession = body
        } catch (err) {
          console.error(err)
        }

        if (adyenSession.state === 'pending_authentication') {
          await new Promise((resolve) => setTimeout(resolve, 500))
        }
      }

      if (adyenSession.state === 'succeeded') {
        setIsCallingBank(false)

        navigate(
          '/success?' + querystring.stringify({ action: adyenSession.action }),
          { replace: true },
        )
      } else {
        sendFeedbackToApp({
          error: true,
          message: adyenSession?.error_message ?? t('default_error_message'),
        })

        setIsCallingBank(false)

        const query = {
          action,
          token,
        }
        if (bulkPaymentId) {
          query.bulk_payment_id = bulkPaymentId
        }

        navigate('/checkout?' + querystring.stringify(query), { replace: true })
      }
    },
    [authenticateAdyenSession, getAdyenSession, bulkPaymentId, adyenSessionId],
  )

  if (isCallingBank) {
    return (
      <LoadingMessage>
        <div>
          <LoadingTitle>{t('3ds_loading_title')}</LoadingTitle>
          <LoadingSubtitle>{t('3ds_loading_subtitle')}</LoadingSubtitle>
        </div>
      </LoadingMessage>
    )
  }
}

export default Callback
